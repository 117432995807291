.support-page {
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-height: calc(100vh - 7.4rem);
    background: rgb(6,121,255);
    background: linear-gradient(135deg, rgba(6,121,255,0) 0%, rgba(6,121,255,0.21) 29%, rgba(72,156,255,0.52) 100%);

    padding: 3.8rem 0rem 3.8rem 0rem;
    gap: 8.5rem;
}

.left-hand-info-panel {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 0.8rem;
    min-width: 35%;
    max-width: 40%;
}

.left-hand-info-panel .back-to-home-button {}

.left-hand-info-panel .page-title-label {
    font-size: 6.4rem;
    color: black;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
}

.left-hand-info-panel .page-info-label {
    font-size: 2.2rem;
    color: black;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
}

.support-form-container {
    min-width: 40%;
    max-width: 50%;
}

.support-form {
    background-color: white;
    border-radius: 1.7rem;
    border-color: #B0B0B0;
    border-style: solid;
    border-width: 0.1rem;
}

.support-form ul {
    padding: 3rem 1rem 3rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3.8rem;
    list-style: none;
    width: 100%;
}

.support-form ul li {
    width: 95%;
}
.support-form .support-form-fields {
    border-radius: 1.2rem;
    border-color: #8F8F8F;
    border-style: solid;
    border-width: 0.1rem;
    padding-left: 1rem;
    resize: none;
    width: 100%;
}

.support-form input {
    height: 6.6rem;
}

.support-form textarea {
    height: 20rem;
}

.support-form-primary-button {
    width: 19.1rem;
    max-width: 100%;
    height: 4.4rem;
    border-radius: 0.8rem;
    border: none;
    background-color: #1773df;
    cursor: pointer;
    outline: none;

    color: white;
    font-size: 2rem;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    cursor: pointer;
}



/* ================ MOBILE AND TABLET VERTICAL FLEXBOX UNDER 950px ================= */
/* ================ MEDIA QUERIES (MAX 700) ================= */
@media screen and (max-width: 700px) {
    .support-page {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: calc(100vh - 7.4rem);
        background: rgb(6,121,255);
        background: linear-gradient(135deg, rgba(6,121,255,0) 0%, rgba(6,121,255,0.21) 29%, rgba(72,156,255,0.52) 100%);

        /*padding: 3.8rem 0rem 3.8rem 0rem;*/
        gap: 4rem;
    }

    .left-hand-info-panel {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        min-width: 80%;
        max-width: 80%;
    }

    .left-hand-info-panel .page-title-label {
        text-align: center;
    }

    .left-hand-info-panel .page-info-label {
        text-align: center;
    }

    .support-form-container {
        min-width: 85%;
        max-width: 90%;
    }

    .support-form ul {
        padding: 2rem 1rem 2rem 1rem;
        gap: 2rem;
    }
}
