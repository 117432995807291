.nav-icon-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: inherit;
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    /*overflow: hidden;*/
    outline: none;
}

.nav-icon-button-img {
    height: 4.4rem;
}

.nav-icon-button-text {
    font-size: 2.6rem;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    padding-left: 1.2rem;
}

/* ================ MEDIA QUERIES (SMALL DEVICES) ================= */
@media screen and (max-width: 559px) {
    .nav-icon-button-img {
        height: 4.0rem;
    }

    .nav-icon-button-text {
        font-size: 2.2rem;
        padding-left: 0.8rem;
    }
}
