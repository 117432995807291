html {
    /*font-size: 62.5%;*/
    font-size: 57%;
}

* {
    box-sizing: border-box;
}

body {
    margin: 0;
}
