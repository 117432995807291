.nav {
    background-color: white;
    color: black;
    height: 7.4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2.2rem;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 10%);
}

.nav ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav a {
    color: inherit;
    font-size: 2.2rem;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    text-decoration: none;
}

.nav .light-links {
    color: #877979;
    margin-right: 2.4rem;
}

.nav .dark-links {
    color: black;
    margin-right: 3.7rem;
}

.nav .nav-download-button {
    margin-right: 0.7rem;
}

/* ================ MEDIA QUERIES (MEDIUM DEVICES) ================= */
@media screen and (min-width: 560px) and (max-width: 850px) {
    .nav {
        padding: 0 1.6rem;
    }

    .nav .light-links {
        color: #877979;
        margin-right: 1.9rem;
    }

    .nav .dark-links {
        margin-right: 2.1rem;
    }
}

/* ================ MEDIA QUERIES (SMALL DEVICES) ================= */
@media screen and (max-width: 559px) {
    .nav {
        padding: 0 0.8rem;
    }

    .nav .nav-download-button {
        margin-right: 0.8rem;
    }
}
