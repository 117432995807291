.main-action-button {
    height: 8.0rem;
    width: 32.0rem;
    border-radius: 0.8rem;
    border: none;
    background: rgb(34,170,246);
    background: linear-gradient(90deg, rgba(34,170,246,1) 23%, rgba(109,85,255,1) 100%);
    cursor: pointer;
    outline: none;
}

.main-action-button label {
    color: white;
    font-size: 2.8rem;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    cursor: pointer;
}
